//変数定義

/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/

//----------------------------------------------------------------
//    レイアウト設定
//----------------------------------------------------------------
$contentWidth    : 1200px;// 全体のコンテンツ幅
$contentPadding  : 50px;//パディング:gridlexでも使用
$padding-break   : 40px;//基本のコンテンツ余白
$padding-content : ($padding-break / 2);//テーブルの上下などの余白指定


/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/

//全体をレスポンシブ非対応にする時は false
$responsiveMode : true;

// max-width(gridlexに合わせる)
$breakpoint-max: (
  'xs': 'screen and (max-width: 568px)', //スマホ境界
  'sm': 'screen and (max-width: 768px)', //タブレット境界
  'md': 'screen and (max-width: 1024px)',
  'content': 'screen and (max-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (max-width: 1280px)',
) !default;

// min-width(gridlexに合わせる)
$breakpoint-min: (
  'xs': 'screen and (min-width: 568px)', //スマホ境界
  'sm': 'screen and (min-width: 768px)', //タブレット境界
  'md': 'screen and (min-width: 1024px)',
  'content': 'screen and (min-width:'$contentWidth + $contentPadding')',
  'lg': 'screen and (min-width: 1280px)',
) !default;


/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/

$white           : #FFFFFF;
$gray            : #CCCCCC;
$black           : #000000;
$gray-l          : lighten( $gray, 15%);
$gray-2          : darken( $gray, 35%);
$red             : #c82e2e;

$keyColor     :#C92835; //キーカラー
//$accentColor     : $red; //アクセントカラー
$keyHoverColor:#9D212B; //ホバーカラー

$bdrColor        : $gray;

/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/

$FontAwesome: 'Font Awesome 5 Free';
$textColor: #333;
//$textColorLight: lighten($textColor,10);
//$textColorDark: darken($textColor,10);
$lineHeight: 1.5; // 基本的な行間

// サンセリフ・ゴシック体
$sanserif: "Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", Hiragino Kaku Gothic ProN, sans-serif;
// セリフ・明朝体
//$serif: "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;


//-----------------------------
//    装飾の定義
//-----------------------------
$border-radius   : 4px;//全体の角丸サイズ
$transition: .3s ease;

//----------------------------------------------------------------
//    ボタンの定義
//----------------------------------------------------------------
$btn-width       : 350px;//PCサイトアクションボタンの大きさ
$border-btn          : none;//テーブルのボーダーカラー
$border-btn-width   : 0px;//テーブルのボーダーサイズ
$border-txt-color   : $white;//テーブルのボーダーサイズ
