///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

// .c-btn {
//   position: relative;
//   display: block;
//   font-size: 16px;
//   padding: 18px 45px 18px 26px;
//   width: 100%;
//   color: $keyColor;
//   background-color: #fff;
//   line-height: 1.25;
//   border: 2px solid $keyColor;
//   text-align: center;
//   font-weight: 600;
//   margin-bottom: 30px;
//   transition: color $transition,
//               background-color $transition;
//   &:last-child {
//     margin-bottom: 0;
//   }
//   &:hover {
//     color: $white;
//     background-color: $keyColor;
//     &:after {
//       right: 10px;
//     }
//   }
//   &:after {
//     @include fa('f054');
//     position: absolute;
//     top: 50%;
//     right: 16px;
//     transform: translateY(-50%);
//     transition: right $transition;
//   }

//   //PDF
//   &[href$=".pdf"] {
//     &:hover {
//       &:after {
//         right: 16px;
//         color: $white;
//       }
//     }
//   }

//   //外部リンク
//   &[target="_blank"] {
//     &:after {
//       color: $keyColor;
//     }
//     &:hover {
//       &:after {
//         right: 16px;
//         color: $white;
//       }
//     }
//   }

//   //テキスト量で長さ可変
//   &__inline {
//     display: inline-block;
//     width: auto;
//     @include mq-max(xs) {
//       width: 100%;
//     }
//   }

//   //小さいボタン
//   &__small {
//     font-size: 14px;
//     padding-top: 10px;
//     padding-bottom: 10px;
//   }

//   //ホバーで背景がスライド
//   &__slide {
//     position: relative;
//     overflow: hidden;
//     transition: all .4s ease;
//     &:before {
//       content: '';
//       width: 100%;
//       height: 100%;
//       background-color: $keyColor;
//       display: block;
//       position: absolute;
//       top: 0;
//       left: 0;
//       transform: translateX(-100%);
//       transition: transform cubic-bezier(0.215, 0.61, 0.355, 1) .4s;
//     }
//     &:hover {
//       background-color: #fff;
//       color: #fff;
//       &:before {
//         transform: translateX(0%);
//       }
//     }
//     &>span {
//       position: relative;
//       z-index: 1;
//     }
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  PFWForm

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-btnArea{
  max-width: $contentWidth;
  margin: 40px auto;
  text-align: center;
  @include mq-max(md) {
    margin: 20px auto;
  }
  @include mq-max(sm) {
    width: 100%;
    margin: 0 auto;
    padding: 10px 0;
  }
}

.c-btn {
  &.c-btnForm {
    display: inline-block;
    width: auto;
    min-width: $btn-width;
    padding: 20px 40px;
    margin-bottom: 10px;
    font-size: 130%;
    display: inline-block;
    background: $keyColor;
    text-align: center;
    border: $border-btn-width solid  $border-btn;
    color: $border-txt-color;
    transition: $transition;
    border-radius: $border-radius;
    text-decoration: none;
    position: relative;
    @include mq-max(sm) {
      display: inline-block;
      min-width: inherit;
      width: 100%;
      margin: 0 0 20px 0;
    }
    &:focus {
      outline: none;
    }

    &:hover{
      background: darken( $keyColor, 15% );
    }

    &.back{
      width: auto;
      line-height: 28px;
      min-width: inherit;
      background: $white;
      border: 1px solid #ccc;
      color: $black;
      font-size: 120%;
      padding-left: 60px;
      margin-right: 20px;
      @include mq-max(sm){
        font-size: 96%;
        min-width: inherit;
        width: 50%;
        max-width: 100%;
        margin: 0 auto;
        padding: 10px 20px;
      }

      &:hover{
        background: lighten( $gray, 15% );
      }

      &:before{
        @include fa('f053');
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        text-align: left;
        @include mq-max(xs) {
          left: 8px;
        }
      }
      &:after {
        content: none;
      }
    }
  }
}
