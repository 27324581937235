@charset "UTF-8";
/* -----base----- */
* {
  box-sizing: border-box;
  line-height: 1.7;
  position: relative
}
body {
  font-family: $sanserif;
  color: $textColor;
  font-size: 16px;
}
h1 {
  margin: 0;
  font-size: 40px;
  line-height: 1.3;
  + p {
    margin-bottom: $contentPadding;
  }
}
h2 {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: .05em;
  margin: 0 0 1.5em;
  line-height: 1.3;
  + ul {
    margin: -1.5em 0 3em
  }
}
h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0 0 1em;
  line-height: 1.3;
}
p {
  margin: 20px 0;
  + {
    h2, h3 {
      margin-top: $contentPadding;
    }
  }
}
ul, ol {
  margin: 20px 0;
  padding-left: 1.5em;
}
img {
  line-height: 0;
  vertical-align: bottom;
  width: 100%;
  max-width: 100%;
  height: auto;
}
a {
  color: $keyColor;
  text-decoration: none;
  &.card {
    color: $textColor;
    transition: $transition;
    &:hover {
      background: #f5f5f5;
      transition: $transition;
    }
  }
}
table {
  border-collapse: collapse;
  th, td {
    padding: 12px 0;
    border-bottom: 1px solid #ccc;
    min-width: 120px;
    text-align: left
  }
}
/* -----button style----- */
.btn {
  background: $keyColor;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  padding: 1em 3em;
  border-radius: $border-radius;
  text-decoration: none;
  line-height: 1;
  transition: $transition;
  &:hover {
    background: $keyHoverColor;
    transition: $transition;
  }
  &.btn-hero {
    font-size: 20px;
    padding: 1.2em 3.5em;
    margin-top: 30px;
  }
  &.btn-out {
    background: #fff;
    color: $keyColor;
    border: 1px solid $keyColor;
    display: table;
    margin: auto;
    transition: $transition;
    &:hover {
      background: $keyColor;
      color: #fff;
      transition: $transition;
    }
  }
  &.btn-cta {
    color: #fff;
    font-size: 40px;
    padding: .7em;
    border: 3px solid #fff;
    border-radius: 0;
    max-width: 700px;
    margin: auto;
    display: block;
    transition: $transition;
    &:hover {
      background: #fff;
      color: $keyColor;
      transition: $transition;
    }
  }
}
/* -----text style----- */
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-red {
  color: $keyColor;
}
.text-light {
  color: #999;
}
.text-light-2 {
  color: $gray-2;
}
.text-sm {
  font-size: 14px;
}
.text-md {
  font-size: 20px;
}
.text-lg {
  font-size: 24px;
}
.text-xl {
  font-size: 50px;
}
.text-bold {
  font-weight: 700;
}
.bg-red {
  background: $keyColor;
  color: #fff;
  padding: 15px;
}
/* -----parts----- */
.footer-block {
  flex: 1 0 0%;
  a {
    text-decoration: none;
    color: #fff;
    display: inline-block;
    margin: .5em 1.5em;
    &:hover {
      text-decoration: underline;
    }
  }
}
.footer-img {
  margin-bottom: 2em;
  img {
    width: auto;
    height: 30px;
  }
}
.pagenation {
  a {
    font-size: 14px;
    font-weight: bold;
    padding: 1em;
    border: 1px solid $keyColor;
    display: inline-block;
    line-height: 1;
    width: 45px;
    height: 45px;
    border-radius: $border-radius;
    margin: 5px;
    text-align: center;
    transition: $transition;
    img {
      width: 8px;
    }
    svg {
      fill: $keyColor;
    }
    &:hover, &.active {
      background: $keyColor;
      color: #fff;
      transition: $transition;
      svg {
        fill: #fff;
      }
    }
    &.pagenation-previous {
      margin-right: 2em;
    }
    &.pagenation-next {
      margin-left: 2em;
    }
  }
  .disable {
    pointer-events: none
  }
  .pc {
    display: flex
  }
}
.logo {
  img {
    width: auto;
    height: 60px;
    margin: 20px;
  }
}
.menu {
  margin-right: 256px;
  justify-content: center;
  align-items: center;
  a {
    &:not(.btn) {
      text-decoration: none;
      font-weight: bold;
      font-size: 14px;
      color: $textColor;
      padding: 10px .5em;
      margin: 0 10px;
      height: 100px;
      border-bottom: 3px solid transparent;
      transition: $transition;
      &:hover, &.active {
        color: $keyColor;
        border-bottom: 3px solid $keyColor;
        transition: $transition;
      }
    }
  }
  .btn {
    margin: 0 0 3px 1.5em;
    font-size: 14px
  }
}
.number {
  font-size: 30px;
  font-weight: bold;
  font-family: roboto;
  font-style: italic;
  color: $keyColor;
  line-height: 1.3;
  margin-top: 3px;
  a {
    color: #fff;
    text-decoration: none;
    line-height: 1.3;
  }
}
.tel {
  width: auto;
  height: 130px;
  background: $keyColor;
  padding: 20px;
  font-size: 12px;
  color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  .number {
    font-style: normal;
    color: #fff;
    padding-left: .7em;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 6px;
      width: 17.67px;
      height: 26px;
      background: url(/inc/image/ico_tel.svg) center/17.67px 26px;
    }
  }
}
.hero {
  width: 100%;
  height: calc(100vh - 100px);
  max-height: 700px;
  background: url(/inc/image/pic_hero.jpg) center/cover;
  color: #fff;
  padding: 1em;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  h1 {
    font-family: roboto;
    font-size: 100px;
    font-weight: bold;
    font-style: italic;
    color: $keyColor;
    position: absolute;
    left: 10px;
    bottom: -.8em;
  }
}
.catch {
  width: 100%;
  height: calc(100vh - 100px);
  max-height: 300px;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 1em;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}
.hero-title, .catch-title {
  font-size: 50px;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 0, 0, .5);
  letter-spacing: .1em;
}
.hero-caption, .catch-caption {
  text-shadow: 0 0 10px rgba(0, 0, 0, .5);
}
.hero-caption {
  font-weight: 700;
}
.title-tag {
  background: $keyColor;
  padding: 7px 25px;
  font-weight: bold;
  font-size: 14px;
  line-height: 1;
  border-radius: 15px;
  color: #fff;
  display: table;
  margin: 0 auto 1em;
}
.fix-logo {
  position: absolute;
  width: auto;
  height: 40px;
  top: 10px;
  left: 10px;
  z-index: 5;
}
.icon {
  margin-right: 30px;
  flex: 0 0 70px;
  img {
    width: 70px;
  }
}
.news {
  border-top: 1px solid #eee;
  margin: $contentPadding 0;
}
.news-item {
  text-decoration: none;
  border-bottom: 1px solid #eee;
  padding: 1.3em 0;
  color: $textColor;
  transition: $transition;
  &:hover {
    background: #f5f5f5;
    transition: $transition;
  }
}
.news-category {
  border: 1px solid $keyColor;
  color: $keyColor;
  font-size: 12px;
  padding: 5px 14px;
  line-height: 1;
  margin: 0 2em;
  transition: $transition;
  min-width: 100px;
  text-align: center;
}
.cta {
  background: $keyColor;
  border-left: 100px solid #fff;
  padding: 120px 20px 120px 120px;
  text-align: center;
  .rotate {
    font-size: 100px;
    font-family: roboto;
    font-style: italic;
    position: absolute;
    top: 270px;
    left: 120px;
    color: #fff;
    transform: rotate(-90deg);
    transform-origin: bottom left;
  }
  h3 {
    color: #fff;
    margin-bottom: 35px;
  }
  .tel {
    position: relative;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    &.flex {}
  }
  .number {
    margin: 0 .5em;
  }
  a:not(.btn) {
    color: #fff;
    &:hover {
      text-decoration: underline;
    }
  }
  .between {
    max-width: 700px;
    margin: auto;
    margin-top: 30px;
  }
}
.round-img {
  margin: 25px;
  flex: 0 0 150px;
  img {
    width: 150px;
    height: 150px;
    border: 1px solid #eee;
    border-radius: 100%;
    object-fit: cover;
  }
}
.round-body {
  border: 1px solid #eee;
  border-radius: 1em;
  background: #fff;
  padding: 30px;
  &.round-body-red {
    border: 1px solid $keyColor;
  }
}
.point {
  margin-top: .5em;
  padding-left: 2.1em;
  &::before {
    content: "!";
    font-weight: bold;
    font-size: 24px;
    background: $keyColor;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: -5px;
    line-height: 1;
  }
}
.point-item {
  + .point-item {
    margin-top: $contentPadding;
  }
}
.check-list {
  padding-left: 2.5em;
  font-size: 20px;
  font-weight: bold;
  color: $keyColor;
  + .check-list {
    margin-top: 20px;
  }
  &::before {
    content: "";
    font-weight: bold;
    font-size: 24px;
    background: $keyColor url(/inc/image/ico_check.svg) center/20px 14.56px no-repeat;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: -2px;
    line-height: 1;
  }
}
.step-title {
  font-size: 24px;
  font-weight: bold;
  color: $keyColor;
  flex: 0 0 200px;
  text-align: center;
  line-height: 1;
}
.step-body {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.3;
}
.step-next {
  height: 40px;
  padding: 10px;
  img {
    width: auto;
    height: 12px;
  }
}
.caution {
  position: absolute;
  right: 20px;
  bottom: 20px;
}
.project-list {
  margin: 0;
  font-size: 18px;
}
.contact-tel {
  font-size: 50px;
  font-weight: bold;
  font-family: roboto;
  font-style: italic;
  color: $keyColor;
  line-height: 1.3;
  display: table;
  margin: auto;
  padding-left: .7em;
  &::before {
    content: "";
    position: absolute;
    left: 4px;
    top: 13px;
    width: 26.5px;
    height: 39px;
    background: url(/inc/image/ico_contact-tel.svg) center/26.5px 39px;
  }
  a {
    line-height: 1.3;
  }
}
.sitemap-card {
  padding-left: $contentPadding;
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
  + .sitemap-card {
    border-left: 1px solid #eee;
  }
}
.category {
  display: inline-block;
  padding: 0 1em;
  &:hover {
    color: $keyHoverColor;
  }
}
.card {
  background: #fff;
  border: 1px solid #eee;
  &.drop {
    box-shadow: 5px 5px 0 $keyColor;
    border: 0;
  }
  &.dot {
    border: 3px dotted $keyColor;
  }
  .card-body {
    padding: 30px;
    z-index: 2;
  }
  .card-title {
    color: $textColor;
    z-index: 2;
  }
  .features-num {
    position: absolute;
    top: 0;
    left: 10px;
    font-size: 50px
  }
  &.story-card {
    .card-body {
      padding: 20px;
    }
  }
}
.breadcrumb {
  padding-top: 10px;
  font-size: 12px;
  margin-bottom: -1em;
  a {
    color: #999;
    text-decoration: underline;
    display: inline-block;
    padding-right: 2em;
    &:hover {
      text-decoration: none;
    }
    &::after {
      content: "/";
      position: absolute;
      right: .5em;
      text-decoration: none
    }
  }
}
.post-entry {
  h2 {
    margin-top: 100px;
    &::before {
      content: "";
      position: absolute;
      width: 50px;
      height: 3px;
      background: $keyColor;
      top: 50%;
      left: -70px;
      transform: translateY(-50%)
    }
    &:first-child {
      margin-top: 0;
    }
  }
}
.post-header-img {
  margin: 30px 0;
  img {
    width: auto;
    max-width: 175px;
  }
}
.list-item {
  margin-bottom: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.share {
  padding-top: 0;
  margin-top: -40px;
}
.notfound {
  font-size: 100px;
  font-weight: bold;
  font-family: roboto;
  font-style: italic;
  color: $keyColor;
  min-height: 500px;
}
.copy {
  background: #111;
  padding: 35px 0;
  margin-top: 100px;
  color: #777;
}
.sp {
  display: none;
}
.toggle {
  width: 30px;
  height: 30px;
  z-index: 10;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
/* -----mediaquery----- */
@include mq-max(lg) {
  .menu {
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    a:not(.btn), a:not(.btn).active {
      height: auto;
      color: #fff;
      font-size: 16px;
      border: 0;
      &:hover {
        color: #fff;
        border: 0;
        opacity: .7
      }
    }
    .btn {
      margin-top: 1em;
      background: #fff;
      color: $keyColor;
      font-size: 20px;
      border: 1px solid #fff;
      padding: 1em 2em;
      margin-left: 20px;
      &:hover {
        background: $keyColor;
        color: #fff;
        transition: $transition;
      }
    }
  }
  .tel {
    position: relative;
    background: none;
    width: auto
  }
  .hero {
    .hero-title {
      font-size: 4.3vw;
    }
    h1 {
      font-size: 9vw;
    }
  }
  .round-img {
    margin: 25px auto 5px;
  }
  .post-entry {
    h2 {
      &::before {
        height: 5px;
        left: -60px;
      }
    }
  }
  .sitemap-card {
    padding: 0;
    + .sitemap-card {
      border: 0
    }
  }
  .sp {
    display: block;
  }
}
@include mq-max(sm) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 24px;
    br {
      display: none
    }
  }
  .btn {
    &.btn-cta {
      font-size: 25px
    }
  }
  .breadcrumb {
    display: none;
  }
  .catch-title, .hero-title {
    font-size: 26px;
  }
  .logo {
    img {
      height: 40px;
      margin: 10px;
    }
  }
  .hero {
    height: 70vh;
    .hero-title {
      font-size: 8vw;
    }
  }
  .icon {
    margin: 0 0 2em
  }
  .news {
    .news-item {
      flex-wrap: wrap;
      flex-direction: row;
    }
    .news-title {
      margin-top: 5px;
    }
  }
  .cta {
    border-left: 7vw solid #fff;
    padding: 15vw 35px 15vw 105px;
    .number {
      font-size: 25px;
      &::before {
        background-size: 13.58px 20px;
        width: 13.58px;
        height: 20px;
      }
    }
    h3 {
      font-size: 4vw
    }
  }
  .footer-block + .footer-block {
    margin-top: 3em;
  }
  .step-title {
    flex: 0 0 40px
  }
  .post-entry {
    h2 {
      margin-top: $contentPadding;
    }
  }
  .pagenation {
    .pc {
      display: none;
    }
  }
  .share {
    margin-top: 0;
  }
  .toggle {
    right: 20px;
  }
}
@media all and (-ms-high-contrast: none) {
  .btn {
    padding-top: 1.3em;
    padding-bottom: 1em;
    &.btn-hero {
      padding-top: 1.3em;
      padding-bottom: 1em;
    }
  }
  .title-tag {
    padding-top: 10px;
    padding-bottom: 5px;
  }
  .news-category {
    padding-top: 7px;
    padding-bottom: 3px;
  }
}
