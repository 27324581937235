/* -----layout----- */
section {
  padding: 100px 0;
  &.gradient {
    background: rgb(255, 245, 246);
    background: linear-gradient(45deg, rgba(255, 245, 246, 1) 0%, rgba(255, 217, 217, 1) 100%);
  }
  &.stripe {
    background-size: auto auto;
    background-color: rgba(255, 255, 255, 1);
    background-image: repeating-linear-gradient(-45deg, transparent, transparent 4px, rgba(238, 238, 238, 1) 4px, rgba(238, 238, 238, 1) 6px);
  }
}
.container {
  width: 100%;
  max-width: calc(#{$contentWidth} + 3em);
  margin: auto;
  padding: 0 1.5em;
}
.container-medi { //追加
  width: 100%;
  max-width: calc(1000px + 3em);
  margin: auto;
  padding: 0 1.5em;
}
.container-thin {
  width: 100%;
  max-width: calc(800px + 3em);
  margin: auto;
  padding: 0 1.5em;
}

.flex {
  display: flex;
  &.cc {
    justify-content: center;
    align-items: center;
  }
  &.ac {
    align-items: center;
  }
  &.jc {
    justify-content: center
  }
  &.column {
    flex-direction: column;
  }
  &.wrap {
    flex-wrap: wrap
  }
  &.between {
    justify-content: space-between
  }
}
.flex-block {
  margin: -25px -25px 25px -25px;
  > *:not(.round-img) {
    margin: 25px;
    flex: 1 0 0%;
  }
  &.wrap-3 {
    flex-wrap: wrap;
    > *:not(.round-img) {
      flex: 0 0 calc((100% - 150px)/3);
    }
  }
  &.wrap-4 {
    flex-wrap: wrap;
    > *:not(.round-img) {
      flex: 0 0 calc((100% - 200px)/4);
    }
  }
}
.flex-logos {
  margin: -15px;
  .item {
    margin: 15px;
    flex: 0 0 175px;
  }
}
@include mq-max(lg) {
  .flex-block {
    margin: -1em -1em 25px -1em;
    > *:not(.round-img) {
      margin: 1em;
      flex: 1 0 0%;
    }
    &.wrap-3 {
      flex-wrap: wrap;
      > *:not(.round-img) {
        flex: 0 0 calc((100% - 6em)/3);
      }
    }
    &.wrap-4 {
      flex-wrap: wrap;
      > *:not(.round-img) {
        flex: 0 0 calc((100% - 8em)/4);
      }
    }
  }
}
@include mq-max(sm) {
  section {
    padding: 60px 0;
  }
  .flex:not(.row) {
    flex-direction: column;
  }
  .flex-block {
    .card-body {
      text-align: center;
    }
    &.wrap-3, &.wrap-4 {
      flex-wrap: nowrap;
    }
  }
  .flex-logos {
    flex-direction: row;
    .item {
      flex: 0 0 140px;
    }
  }
}
