nav {
  flex: 1 0 0%;
}
@include mq-max(lg) {
  nav {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 290px;
    height: 100vh;
    background: $keyColor;
    z-index: 5;
    padding: 100px 1em;
    transform: translateX(100%);
    transition: $transition;
    &.on {
      box-shadow: 0 0 15px rgba(0, 0, 0, .5);
      transform: translateX(0);
      transition: $transition;
    }
  }
}
