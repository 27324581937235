header {
  width: 100%;
  height: 100px;
  background: #fff;
  border-bottom: 1px solid #eee;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 6;
}
@include mq-max(lg) {
  header {
    justify-content: space-between;
  }
}
@include mq-max(sm) {
  header {
    flex-direction: row !important;
    height: 60px;
  }
}
