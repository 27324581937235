//ユーティリティ群
@import "../util/all";

#works {
  .catch {
    background-image: url(../image/pic_01.jpg)
  }
  a.card {
    min-width: 262.5px;
  }
}
#story {
  .catch {
    background-image: url(../image/pic_18.jpg);
    &__tsuda {
      background-image: url(../image/pic_tsuda-catch.jpg);
      background-position: 50% 58%;
    }
    &__koryo {
      background-image: url(../image/pic_koryo-catch.jpg);
    }
  }
  .p-pb0{
    padding-bottom: 0px;
  }
}
#contact {
  .catch {
    background-image: url(../image/pic_19.jpg)
  }
  .p-pb0{
    padding-bottom: 0px;
  }
  .p-mb10{
    margin-bottom: 10px;
  }
  .p-text-large{
    font-size: 30px;
  }
}
#faq {
  section {
    counter-reset: number 0;
  }
  .question {
    padding-left: 2.5em;
    &::before {
      counter-increment: number 1;
      content: "Q"counter(number)".　";
      position: absolute;
      left: 0;
    }
  }
  .catch {
    background-image: url(../image/pic_20.jpg)
  }
}
#features {
  .catch {
    background-image: url(../image/pic_15.jpg)
  }
}
#howto {
  .catch {
    background-image: url(../image/pic_16.jpg)
  }
}
#news {
  .catch {
    background-image: url(../image/pic_21.jpg)
  }
}
#news-detail {
  .catch {
    background-image: url(../image/pic_22.jpg)
  }
}
#privacy {
  .catch {
    background-image: url(../image/pic_23.jpg)
  }
}
#project {
  .catch {
    background-image: url(../image/pic_24.jpg)
  }
}
#sitemap {
  .catch {
    background-image: url(../image/pic_25.jpg)
  }
}

// 追記 ==========================================
#features {
  .round-body{
    > p {
      color: $black;
      font-weight: 500;
    }
    > .c-small-text {
      font-weight: 500;
    }
  }
}
